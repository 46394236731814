import React from 'react';
import type { PageProps } from 'gatsby';
import { graphql } from 'gatsby';
import type { IGatsbyImageData } from 'gatsby-plugin-image';
import { getImage } from 'gatsby-plugin-image';
import Learn from '@clearscore/website.learn-portal';
import { LEARN_PORTAL_CATEGORY_TYPES } from '@clearscore/constants.global-website';

import Layout from '../components/layout';

const getArticlesFromNode = ({
    articles,
}: any): Array<{
    id: string;
    url: string;
    title: string;
    description: string;
    author: string;
    category: string;
    categoryUrl: string;
    wordCount: number;
    timeToRead: number;
    publishedDate: string;
    image: IGatsbyImageData;
    authorImage: IGatsbyImageData;
}> =>
    articles.map(
        ({
            id,
            slug: url,
            author: { name: authorName },
            props: { primaryCategory: category, primaryCategoryUrl: categoryUrl },
            publishedDate,
            childMdx: { wordCount, timeToRead },
            language: { title, description },
            heroImageNode,
            authorImageNode,
        }) => ({
            id,
            url,
            title,
            description,
            author: authorName || '',
            category,
            categoryUrl,
            wordCount: wordCount.words,
            timeToRead,
            publishedDate,
            // todo: test mostRecents don't have images.
            image: getImage(heroImageNode),
            authorImage: getImage(authorImageNode),
        }),
    );

const CategoryLandingPage = ({
    data: { allLearnCategoryPortal },
    location,
}: PageProps<Queries.LearnCategoryPortalQuery>): React.ReactElement => {
    const { sections, meta, mostRecent, categories } = allLearnCategoryPortal.nodes[0];
    const sectionFeatured = sections.find(({ type }) => type === 'featured');
    const sectionHero = sections.find(({ type }) => type === LEARN_PORTAL_CATEGORY_TYPES.HERO);
    const otherSections = sections.filter(({ type }) => type === LEARN_PORTAL_CATEGORY_TYPES.OTHER);
    // todo: add test for when articlesNode array is empty + take a look at the generated types.
    const heroImage = (sectionHero.articlesNode as any).length ? sectionHero.articlesNode[0].heroImageNode : null;

    const mostRecentArticles = {
        items: getArticlesFromNode({ articles: mostRecent.articlesNode }),
        title: mostRecent.language.sectionTitle,
    };

    const populatedSectionHero = {
        items: getArticlesFromNode({ articles: sectionHero.articlesNode }),
        position: sectionHero.position,
    };

    const featuredArticles = {
        items: getArticlesFromNode({ articles: sectionFeatured.articlesNode }),
        title: sectionFeatured.title,
        position: sectionFeatured.position,
    };

    const populatedOtherSections = otherSections.map(({ articlesNode, title, description, position }) => ({
        items: getArticlesFromNode({ articles: articlesNode }),
        title,
        description,
        position,
    }));

    return (
        <Layout meta={{ ...meta, image: heroImage?.publicURL || '' }} location={location.pathname}>
            {({ commonModules }): React.ReactElement => (
                <Learn
                    // @ts-expect-error fix after migration to TS
                    sectionHero={populatedSectionHero}
                    // @ts-expect-error fix after migration to TS
                    sectionFeatured={featuredArticles}
                    // @ts-expect-error fix after migration to TS
                    sectionMostRecent={mostRecentArticles}
                    // @ts-expect-error fix after migration to TS
                    otherSections={populatedOtherSections}
                    cookiePolicyConfig={commonModules.cookie_policy}
                    appStoreConfig={commonModules.app_store}
                    // @ts-expect-error fix after migration to TS
                    categories={categories}
                    isLandingPage
                    hasSubNav
                />
            )}
        </Layout>
    );
};

export const query = graphql`
    query LearnCategoryPortal($id: String) {
        allLearnCategoryPortal(filter: { id: { eq: $id } }) {
            nodes {
                id
                locale
                category
                meta {
                    description
                    title
                    url
                }
                sections {
                    type
                    title
                    description
                    position
                    articlesNode {
                        id
                        slug
                        publishedDate
                        childMdx {
                            timeToRead
                            wordCount {
                                words
                            }
                        }
                        language {
                            title
                            description
                        }
                        hero {
                            language {
                                altText
                            }
                        }
                        author {
                            name
                        }
                        props {
                            primaryCategory
                            primaryCategoryUrl
                        }
                        heroImageNode {
                            publicURL
                            childImageSharp {
                                gatsbyImageData(
                                    quality: 90
                                    breakpoints: [375, 768, 1024, 1200, 1440]
                                    layout: FULL_WIDTH
                                )
                            }
                        }
                        authorImageNode {
                            childImageSharp {
                                gatsbyImageData(
                                    quality: 90
                                    breakpoints: [375, 768, 1024, 1200, 1440]
                                    layout: FULL_WIDTH
                                )
                            }
                        }
                    }
                }
                mostRecent {
                    articlesNode {
                        id
                        slug
                        publishedDate
                        formattedPublishDate
                        props {
                            author
                            primaryCategory
                            primaryCategoryUrl
                            slug
                        }
                        language {
                            title
                            description
                        }
                        author {
                            name
                        }
                        childMdx {
                            wordCount {
                                words
                            }
                            timeToRead
                        }
                    }
                    language {
                        sectionTitle
                    }
                }
                categories {
                    navTitle
                    categoryNavPosition
                    categoryUrl
                }
            }
        }
    }
`;

export default CategoryLandingPage;
